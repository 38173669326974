import React from 'react';
import SpecialCard from '../SpecialCard';


/**
* @author
* @function Thesis
**/

const Thesis = (props) => {
    const {publication} = props
  return(
        <SpecialCard group= "true">
        
        <p className="publication">
              {publication.url? (<span><a href={publication.url} title="Download" className="special-btn"><i className="fa fa-download"></i></a></span>):('')}  {publication.youtube? (<a href={publication.youtube} title="Presentation" className="special-btn youtube"><i className="fa fa-youtube"></i></a>):('')}
              {publication.authors? (<span>{publication.authors}.</span>):('')} {publication.title? (<span><b>{publication.title}</b>.</span>):('')} {publication.type? (<span><i>{publication.type}</i>,</span>):('')}  {publication.instituition? (<span>{publication.instituition}, </span>):('')}   
              {publication.country? (<span>{publication.country},</span>):('')}  {publication.instituition? (<span>{publication.instituition},</span>):('')}  {publication.year? (<span>{publication.year}.</span>):('')}  
              </p>
   </SpecialCard>
   )

 }

export default Thesis;
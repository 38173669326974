import React from 'react';
import './style.css';

/**
* @author
* @function Award
**/

const Award = (props) => {
    const {award} = props
    const url = award.url;
    const youtube = award.youtube;
  return(
    <div className="items">
        <p>{award.received? (<span>{award.received}.</span>):('')} 
        </p> 
         {url? (<a href={url} className="special-btn"><span>See more</span></a>): '' } {youtube? (<a href={youtube} title="Presentation" className="special-btn youtube"><i className="fa fa-youtube"></i></a>): '' }
          
      </div>
   )

 }

export default Award;
import React from 'react';
import './style.css';

/**
* @author
* @function Card
**/
/*{{width: props.width?props.width:'100%', background:props.background?props.background:'white'}} */
const Card = (props) => {
  return(
    <div className="card" style={props.style}>
        {props.children}
    </div>
   )

 }

export default Card;
import React from 'react';
import './style.css';

/**
* @author
* @function SpecialCard
**/
/*{{width: props.width?props.width:'100%', background:props.background?props.background:'white'}} */
const SpecialCard = (props) => {
  const {group} = props
  return(
    <div className={group? "specialCard cell": "specialCard"} style={props.style}>
        {props.children}
    </div>
   )

 }

export default SpecialCard;
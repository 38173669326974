import React from 'react';
import Card from '../../components/UI/Card';
import './style.css';
import { HashLink as Link } from 'react-router-hash-link';

/**
* @author
* @function NotFound
**/


const NotFound = (props) => {
  return(
   <main>
      <section className="page">
        
        <Card style={{backgroundImage: 'url(/public/images/about-img.png)'}}>
        <div className="black-v">
              <div className="black-h">
                  <div className="pageHeader">
                    <div className="pageHeaderContainer">
                      <div className="div-with-video">
                          <h1 className="title">
                            <span className="effect-1">404</span> - Page not found!!!  
                          </h1>
                          <div className="pageContainer">
                          <p>
                            The <span className="effect-1">page</span> you are looking for <span className="effect-1">does not exist</span> or <span className="effect-1">has been moved</span>.
                            Please let me <span className="effect-1">know</span> what you were <span className="effect-1">looking for</span> by sending me an <span className="effect-1">email</span> (see my contact below). 
                          </p>

                          <div className="items">
                              <Link to="/" className="special-btn">Go back!</Link>
                          </div>

                        </div>
                      </div>
                
                    </div>
                  </div>
                </div>
              </div> 

        </Card>
        
       </section>
    </main>
   )

 }

export default NotFound;
import React from 'react';
import SpecialCard from '../SpecialCard';


/**
* @author
* @function Article
**/

const Article = (props) => {
    const {publication} = props
  return(
        <SpecialCard group= "true">
        
        <p className="publication">
                {publication.url? (<span><a href={publication.url} title="Download" className="special-btn">{publication.doi? /*publication.doi*/(<i className="fa fa-download"></i>): ((<i className="fa fa-download"></i>)/*<i>PDF</i>*/)}</a></span>):('')}  {publication.youtube? (<a href={publication.youtube} title="Presentation" className="special-btn youtube"><i className="fa fa-youtube"></i></a>):('')}
                {publication.acm? (<img src="https://dl.acm.org/images/oa.gif" width="25" height="25" border="0" alt={publication.acm} style={{verticalAlign:"middle"}} />):('')}   
                {publication.authors? (<span>{publication.authors}.</span>):('')} {publication.title? (<span><b>{publication.title}</b>.</span>):('')} {publication.journal? (<span><i>{publication.journal}</i>, </span>):('')}  
                {publication.volume? (<span>{publication.volume}{publication.number? (<span>({publication.number}){publication.pages? (<span>:{publication.pages}, </span>):(', ')}</span>):('')}</span>):('')}   
                {publication.year? (<span>{publication.year}.</span>):('')}  
                </p>
        
        </SpecialCard>
   )

 }

export default Article;
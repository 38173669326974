import React from 'react';
import SpecialCard from '../SpecialCard';


/**
* @author
* @function Report
**/

const Report = (props) => {
    const {publication} = props
  return(
        <SpecialCard group= "true">
        
        <p className="publication">
                {publication.url? (<span><a href={publication.url} title="Download" className="special-btn">{publication.doi? /*publication.doi*/ ((<i className="fa fa-download"></i>)): ((<i className="fa fa-download"></i>)(<i className="fa fa-download"></i>)/*<i>PDF</i>*/)}</a></span>):('')} {publication.youtube? (<a href={publication.youtube} title="Presentation" className="special-btn youtube"><i className="fa fa-youtube"></i></a>):('')}
                {publication.authors? (<span>{publication.authors}.</span>):('')} {publication.title? (<span><b>{publication.title}</b>. </span>):('')}
                {publication.instituition? (<span>{publication.instituition}, {publication.city? (<span>{publication.city},</span>):('')} {publication.year}. {publication.arxiv? (<span>Also as arXiv Report no. {publication.arxiv}. </span>):('')}</span> ):(publication.arxiv? (<span> arXiv Report no. {publication.arxiv}, {publication.year}. </span>):(''))}   
                </p>
   </SpecialCard>
   )

 }

export default Report;
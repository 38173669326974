import React from 'react';
import Card from '../../components/UI/Card';
import './style.css';

/**
* @author
* @function Loading
**/


const Loading = (props) => {
  return(
    <main>
      <section className="page">
        
        <Card style={{backgroundImage: 'url(/public/images/about-img.png)'}}>
        <div className="black-v">
              <div className="black-h">
                  <div className="pageHeader">
                    <div className="pageHeaderContainer">
                      <div className="div-with-video">
                          <h1 className="title">
                            <span className="effect-1">Please</span>, wait a moment...  
                          </h1>
                          <div className="pageContainer loading">
                                                  
                            <div class="loader"></div>
                          
                          
                        </div>
                      </div>
                
                    </div>
                  </div>
                </div>
              </div> 
         </Card>
        
      </section>
    </main>
   )

 }

export default Loading;
import React, {useState, useEffect} from 'react';
import './style.css';
import Card from '../../components/UI/Card';
import Loading from '../Loading';
//import { useFetch } from '../../services/useFetch';
import Video from '../../components/UI/Video';
import api from '../../services/api';
import { HashLink as Link } from 'react-router-hash-link';
import SpecialCard from '../../components/UI/SpecialCard';

/**
* @author
* @function Press
**/
/*class Press extends Component{
  state = {
    isReaded: false,
    data: []
  }
*/
const Press = () =>{


  const [isReaded, setIsReaded] = useState(true);
  const [data, setData] = useState(
    
    { "press":[
      {
          "type": "blogs",
          "title": "Blogs",
          "publications":[
              {
                  "authors": "Jim Fruchterman",
                  "title": "Austria conference on access technology",
                  "url": "https://benetech.blogspot.com/2008/07/austria-conference-on-access-technology.html",
                  "name": "Benetech Blog",
                  "date": "July 17, 2008",
                  "note": "Note: Jim Fruchterman (MacArthur Fellow in 2006) praises the EasyVoice work on the Benetech Blog"
              }
      ]
      },
      {
          "type": "media",
          "title": "The  Portuguese Media",
          "publications":[
              {
                  "authors": "Idálio Revez",
                  "title": "Quando uma região não consegue aproveitar um dos seus melhores",
                  "url": "https://www.publico.pt/2017/01/28/local/noticia/engenheiro-criador-de-aplicacoes-para-deficientes-nao-encontra-trabalho-em-portugal-1759891",
                  "name": "Jornal Público",
                  "date": "January 28, 2017"
                  
              },
              {
                  "authors": "Fátima Lopes",
                  "title": "A Tarde é Sua",
                  "name": "National TV station - TVI",
                  "date": "August 25, 2011"
                  
              },
              {
                  "authors": "Nuno Graciano and Merche Romero",
                  "title": "Companhia dos Presentes",
                  "name": "National TV station - SIC",
                  "date":"November 22, 2010"
                  
              },
              {
                  "authors": "Marta Vilar",
                  "title": "Entrevista ao Paulo Condado e Fernando Lobo, pela Marta Vilar e restante equipa ETIC, durante o TEDxEdges 01-10-2010, sobre o projecto easy-voice",
                  "url": "https://www.youtube.com/watch?v=6pFnQdOXU9Y",
                  "name": "ETIC",
                  "date": "October 01, 2010",
                  "novideos":[
                      {
                          "url":"https://www.youtube-nocookie.com/embed/6pFnQdOXU9Y",
                          "title":"Entrevista ao Paulo Condado e Fernando Lobo, pela Marta Vilar e restante equipa ETIC, durante o TEDxEdges 01-10-2010, sobre o projecto easy-voice"
                      }
                  ]
              },
              {
                  "authors": "Teresa Santos",
                  "title": "Easy Voice: falar ao telefone nunca foi tão fácil",
                  "url": "https://tvi24.iol.pt/skype/computador/easy-voice-falar-ao-telefone-nunca-foi-tao-facil",
                  "name": "National TV station - TVI",
                  "date": "December 14, 2009"
                  
              },
              {
                  "authors": "Ana Sofia Rodrigues",
                  "title": "Jovem com paralisia cerebral criou novo sistema informático que permite que pessoas com deficiências na fala se façam entender",
                  "url": "https://www.rtp.pt/noticias/pais/jovem-com-paralisia-cerebral-criou-novo-sistema-informatico-que-permite-que-pessoas-com-deficiencas-na-fala-se-facam-entender_v302751",
                  "name": "National TV station - RTP (Rádio e Televisão de Portugal)",
                  "date":"December 13, 2009"
                  
              },
              {
                  "authors": "Maria Augusta Casaca",
                  "title": "Deficiente com paralisia cerebral cria software que faz síntese de voz",
                  "url": "https://www.tsf.pt/vida/deficiente-com-paralisia-cerebral-cria-software-que-faz-sintese-de-voz-1437919.html",
"name": "National radio station - TSF",
                  "date": "December 3, 2009"
                  
              },
              {
                  "authors": "Susana Esteves",
                  "title": "Universidades Impulsionam Evolução Tecnológica",
                  "name": "Revista PCGuia",
                  "date": "November, 2009"
                  
              },
              {
                  "authors": "Fernanda de Oliveira Ribeiro and Luís Pinto",
                  "title": "Diferente... forma de vida",
                  "url": "https://sicnoticias.pt/programas/reportagemespecial/2008-01-11-diferente...-forma-de-vida",
                  "name": "National TV station - SIC",
                  "date":"June 27, 2007"
                  
              }
          
      ]
      }
]
}


);

  useEffect(() => {
    async function getData(){
      try{
        const request = await api.get('press/');
        setData(request.data);
        setIsReaded(true);
        return request;
      }
      catch(e){
        return null;
      }
    } 
    getData();
  }, []);
    
  

  const renderPress =() =>{
  //  let data = this.state.data;
    return(
        <span>
        {data.press?.map((press, index) =>{
              if(press.title){
                return(
                  <Card key={index}>
                    <h3>{press.title}</h3>
                    {press.publications?.map((publication, index) =>{
                      return(
                          <SpecialCard key={index}>
                          <div className="press">
                              <p>  
                                {publication.authors?(<span>{publication.authors}, </span>):('')}
                                {publication.title?(publication.url?(<span><a href={publication.url} title={publication.title}>{publication.title}</a>. </span>):(<span><b>{publication.title}</b>. </span>)):('')}
                                {publication.name?(<span>{publication.name}, </span>):('')}
                                {publication.date?(<span>{publication.date}. </span>):('')}
                                {publication.other? (publication.other.url? (<span>(<a href={publication.other.url} title={publication.other.title}>{publication.other.title? (publication.other.title):('Other link')}</a>)</span>):('')):('')}
                                {publication.note?(<span><br /> <small>{publication.note}.</small></span>):('')}
                              </p>
                              {publication.videos?(
                                <div className="pageContainer" style={{backgroundColor:"#012"}}>
                                      {publication.videos?.map((video, index) =>{
                                      return(
                                        <Card key={index}>
                                          <Video video={video} />
                                        </Card>
                                      )
                                    })}
                                </div>
                              ):('')}
                                  
                             
                                    
                            </div>     
                          </SpecialCard>)
                    })}
                  </Card>
                  
                  )  
                }
                return '';
              })
              }
          </span>)             
    }

  //render(){
    if (!isReaded){
      return (<Loading />);
    }
   /* */
   
    return(
      <main>
        <section className="page">
        
          <Card style={{backgroundImage: 'url(/public/images/press-img.png)'}}>
          <div className="black-v">
              <div className="black-h">
                  <div className="pageHeader">
                    <div className="pageHeaderContainer">
                      <div className="div-with-video">
                          <h1 className="title">
                            I <span className="effect-1">have appeared</span> on media.  
                          </h1>
                          <div className="pageContainer">
                          <p>
                            My <span className="effect-1">research work</span> got the <span className="effect-1">attention</span> of the Portuguese <span className="effect-1">media</span>, 
                            namely from the national TV stations (<span className="effect-1">RTP</span>, <span className="effect-1">SIC</span> and <span className="effect-1">TVI</span>), the national 
                            radio station - <span className="effect-1">TSF</span>, the <span className="effect-1">PCGUIA magazine</span>, and some <span className="effect-1">newspapers</span>.

                          </p>
                          <div className="items">
                              <Link to="/press#media" className="cir-btn"><i className="fa fa-angle-down"></i></Link>
                          </div>

                        </div>
                      </div>
                
                    </div>
                  </div>
                </div>
              </div> 
          
          </Card>
          


          <div  id="media" className="empty-bar" style={{paddingBottom: '86px'}}></div>
          {renderPress()}
          <div className="empty-bar"></div>


        
        </section>
      </main>
     )

  }
//}

/*
const Press = (props) => {
  return(
    
    <section className="press">
     
      <Card style={{backgroundImage: 'url(/public/images/press-img.png)'}}>
        <div className="pressHeader">
          <div>
            <h1 className="title">
              Press
            </h1>
            <div className="pressContainer">
              <p>My research work got the attention of the Portuguese media, namely from the national TV stations (RTP, SIC and TVI), the national radio station TSF, the PCGUIA magazine, and some newspapers.</p>
            </div>
          </div>
        </div>
      </Card>
      <SpecialCard>
        <h3>Blogs</h3>
        <p>Jim Fruchterman, <a href="https://benetech.blogspot.com/2008/07/austria-conference-on-access-technology.html">Austria conference on access technology.</a> Benetech 
        Blog, July 17, 2008.<br /><small><i>Note: Jim Fruchterman (MacArthur Fellow in 2006) praises the EasyVoice work on the Benetech Blog.</i></small></p>        
      </SpecialCard>
      <SpecialCard>
        <h3>The Portuguese Media</h3>
        <p>Idálio Revez, <a href="https://www.publico.pt/2017/01/28/local/noticia/engenheiro-criador-de-aplicacoes-para-deficientes-nao-encontra-trabalho-em-portugal-1759891">Quando uma região não consegue aproveitar 
        um dos seus melhores.</a> Jornal Público, January 28, 2017.</p>
    
        <p>Fátima Lopes, <b>A Tarde é Sua.</b> National TV station - TVI, August 25, 2011.</p>

        <p>Nuno Graciano and Merche Romero, <a href="https://videos.sapo.pt/epoQWRZw6ffkGeNqW2tT">Companhia dos Presentes.</a> National TV station - SIC, November 22, 2010.</p>
        
        <p>Marta Vilar, <a href="https://www.youtube.com/watch?v=6pFnQdOXU9Y">Entrevista ao Paulo Condado e Fernando Lobo, pela Marta Vilar e restante equipa ETIC, durante o TEDxEdges 01-10-2010, sobre o projecto easy-voice.</a> ETIC, October 01, 2010. </p>
        <div className="pressContainer">
          <Card></Card>
          <SpecialCard>
            <div className="media"> 
              <iframe src="https://www.youtube-nocookie.com/embed/6pFnQdOXU9Y"  title="Entrevista ao Paulo Condado e Fernando Lobo, pela Marta Vilar e restante equipa ETIC, durante o TEDxEdges 01-10-2010." allowFullScreen>Entrevista ao Paulo Condado e Fernando Lobo, no final do TEDxEdges 01-10-2010</iframe>
            </div> 
          </SpecialCard>
          <Card></Card>
        </div>

        <p>Teresa Santos, <b>Easy Voice: falar ao telefone nunca foi tão fácil. </b> National TV station - TVI, December 14, 2009.</p>

        <p>Ana Sofia Rodrigues, <a href="https://www.rtp.pt/noticias/pais/jovem-com-paralisia-cerebral-criou-novo-sistema-informatico-que-permite-que-pessoas-com-deficiencas-na-fala-se-facam-entender_v302751">Jovem com paralisia cerebral criou novo sistema 
        informático que permite que pessoas com deficiências na fala se façam entender.</a> National TV station - RTP (Rádio e Televisão de Portugal), December 13, 2009.</p>

        <p>Maria Augusta Casaca, <b>Deficiente com paralisia cerebral cria software que faz síntese de voz.</b> National radio station - TSF, December 3, 2009.</p>

        <p>Susana Esteves, <b>Universidades Impulsionam Evolução Tecnológica.</b> Revista PCGuia, November, 2009.</p>

        <p>Fernanda de Oliveira Ribeiro and Luís Pinto, <a href="https://videos.sapo.pt/LrQnBm8lkzyLbvGPyVDP">Diferente... forma de vida.</a> National TV station - SIC, June 27, 2007. (<a href="https://videos.sapo.pt/EtM2T9bqrk67R1D0zLuA">Paralisia cerebral não impede doutoramento</a>)</p>
        
      </SpecialCard>
     </section>
   )

 }*/

export default Press;
import React from 'react';
import SpecialCard from '../SpecialCard';


/**
* @author
* @function Paper
**/

const Paper = (props) => {
    const {publication} = props
  return(
        <SpecialCard group= "true">
         <p className="publication">
                  {publication.url? (<span><a href={publication.url} title="Download" className="special-btn">{publication.doi? /*publication.doi*/ (<i className="fa fa-download"></i>): ((<i className="fa fa-download"></i>)/*<i>PDF</i>*/)}</a></span>):('')} {publication.youtube? (<a href={publication.youtube} title="Presentation" className="special-btn youtube"><i className="fa fa-youtube"></i></a>):('')}
                  {publication.acm? (<img src="https://dl.acm.org/images/oa.gif" width="25" height="25" border="0" alt={publication.acm} style={{verticalAlign:"middle"}} />):('')}   
                  {publication.authors? (<span>{publication.authors}.</span>):('')} {publication.title? (<span><b>{publication.title}</b>.</span>):('')} {publication.titlebook? (<span>In <i>{publication.titlebook}</i>, </span>):('')}  
                  {publication.publication? (<span>{publication.publication},</span>):('')} {publication.volume? (<span>Vol. {publication.volume},</span>):('')} {publication.pages? (<span>pages {publication.pages},</span>):('')} {publication.publisher? (<span>{publication.publisher},</span>):('')}  
                  {publication.city? (<span>{publication.city},</span>):('')} {publication.year}.
                  </p> 
   </SpecialCard>
   )

 }

export default Paper;
import React from 'react';
import SpecialCard from '../SpecialCard';


/**
* @author
* @function Video
**/

const Video = (props) => {
    const {video} = props
  return(
        <SpecialCard group= "true">
        <div className="media">
        <iframe src={video.url} title={video.title}  frameBorder="0" allowFullScreen>{video.title}</iframe>
        </div>
        <p>{video.title}. {video.duration ? (<span>({video.duration})</span>):''}</p>
   </SpecialCard>
   )

 }

export default Video;
import React, {useState, useEffect} from 'react';
import './style.css';
import Card from '../../components/UI/Card';
//import SpecialCard from '../../components/UI/SpecialCard';
//import { useFetch } from '../../services/useFetch';
//
import api from '../../services/api'; 
import Loading from '../Loading';
import { HashLink as Link } from 'react-router-hash-link'
import Paper from '../../components/UI/Paper';
import Report from '../../components/UI/Report';
import Article from '../../components/UI/Article';
import Thesis from '../../components/UI/Thesis';

/**
* @author
* @function Publications
**/

/*class Publications extends Component{
  state = {
    isReaded: true,
    data: []
  }*/

  const Publications = () => {

  
    const [isReaded, setIsReaded] = useState(true);
    const [data, setData] = useState(
      { "publications":[
        {
            "type": "theses",
            "title": "Theses",
            "publications":[
                {
                    "authors": "P. A. Condado",
                    "title": "Quebra de barreiras de comunicação para portadores de paralisia cerebral",
                    "type": "PhD thesis",
                    "instituition": "Universidade do Algarve",
                    "country": "Portugal",
                    "year": "2009"

                }
        ]
        },

        {

            "type": "articles",
            "title": "Journal Articles",
            "publications": [
  {
                    "authors": "P. A. Condado and F. G. Lobo",
                    "title": "Security and privacy concerns in assisted living environments",
                    "journal": "Journal of Smart Cities and Society",
                    "volume": "2",
                    "number": "2",
  "pages":"99-121",
                    "year": "2023",
                    "url": "https://doi.org/10.3233/SCS-230015",
                    "doi": "10.3233/SCS-230015"
                },
                {
                    "authors": "P. A. Condado, F. G. Lobo and T. Carita",
                    "title": "Towards Richer Assisted Living Environments",
                    "journal": "SN Computer Science",
                    "volume": "3",
                    "number": "1",
                    "year": "2022",
                    "url": "https://doi.org/10.1007/s42979-021-00983-0",
                    "doi": "10.1007/s42979-021-00983-0"
                },
                {
                    "authors": "R. Godinho, P. A. Condado, M. Zacarias and F. G. Lobo",
                    "title": "Improving accessibility of mobile devices with easywrite",
                    "journal": "Behaviour & Information Technology",
                    "volume": "34",
                    "number": "2",
                    "pages": "135-150",
                    "year": "2015",
                    "url": "https://doi.org/10.1080/0144929X.2014.981584",
                    "doi": "10.1080/0144929X.2014.981584"
                },
                {
                    "authors": "D. Datta, K. Deb, C. M. Fonseca, F. G. Lobo, P. A. Condado, and J. Seixas",
                    "title": "Multi-objective evolutionary algorithm for land-use management problem",
                    "journal": "International Journal of Computational Intelligence Research",
                    "volume": "3",
                    "number": "4",
                    "pages": "371-384",
                    "year": "2007"
                }
        ]
        },

        {

            "type": "papers",
            "title": "Conference Papers",
            "publications":[
                {
                    "acm":"ACM DL Author-ize service",
                    "authors": "P. A. Condado and F. G. Lobo",
                    "title": "A System for Controlling Assisted Living Environments Using Mobile Devices",
                    "titlebook": "Proceedings of the 17th International ACM SIGACCESS Conference on Computers and Accessibility (ASSETS 2015)",
                    "pages": "33-38",
                    "publisher": "ACM Press",
                    "year": "2015",
                    "url": "https://dl.acm.org/doi/10.1145/2700648.2809839?cid=81384619415",
                    "doi": "10.1145/2700648.2809839",
                    "youtube":"https://youtu.be/JjJYrsSYbnM"
                },
                {
                    "authors": "P. A. Condado, R. Godinho, M. Zacarias and F. G. Lobo",
                    "title": "EasyWrite: A touch-based entry method for mobile devices",
                    "titlebook": "Proceedings of the 13th IFIP TC13 International Conference on Human-Computer Interaction (INTERACT 2011), Workshop on Mobile Accessibility (MOBACC 2011)",   
                    "city": "Lisboa, Portugal",
                    "year": "2011"
                },
                {
                    "authors": "P. A. Condado and F. G. Lobo",
                    "title": "EasyVoice: Breaking barriers for people with voice disabilities",
                    "titlebook": "Proceedings of the 11th International Conference on Computers Helping People with Special Needs (ICCHP 2008)",
                    "publication":"Lecture Notes in Computer Science",
                    "volume":"5105",
                    "pages": "1228-1235",
                    "publisher": "Springer",
                    "year": "2008",
                    "url": "https://dx.doi.org/10.1007/978-3-540-70540-6_185",
                    "doi": "10.1007/978-3-540-70540-6_185"
                },
                {
                    "acm":"ACM DL Author-ize service",
                    "authors": "P. A. Condado and F. G. Lobo",
                    "title": "EasyVoice: integrating voice synthesis with Skype",
                    "titlebook": "Proceedings of the Ninth International ACM SIGACCESS Conference on Computers and Accessibility (ASSETS 2007)",
                    "pages": "235-236",
                    "publisher": "ACM Press",
                    "year": "2007",
                    "url": "https://dl.acm.org/doi/10.1145/1296843.1296889?cid=81384619415",
                    "doi": "10.1145/1296843.1296889"
                },
                {
                    "authors": "S. Norte, P. A. Condado, and F. G. Lobo",
                    "title": "Learning Logo at a high school: Constructionism versus Objectivism",
                    "titlebook": "Proceedings of the 11th Eurologo Conference",
                    "city": "Bratislava, Slovakia",
                    "year": "2007"
                },
                {
                    "authors": "S. Norte, N. Castilho, P. A. Condado, and F. G. Lobo",
                    "title": "GoGoBoard and Logo programming for helping people with disabilities",
                    "titlebook": "Proceedings of the 10th Eurologo Conference",
                    "pages": "171-178",
                    "city": "Warsaw, Poland",
                    "year": "2005",
                    "url":"/papers/E2005NorteEtAl.pdf"
                },
                {
                    "authors": "P. A. Condado, P. F. Miquelina, S. Norte, N. Castilho, F. G. Lobo, and H. Shahbazkia",
                    "title": "Information and communication technologies for people with disabilities",
                    "titlebook": "CDROM Proceedings of ICL 2004: Interactive Computer Aided Learning International Conference",
                    "publisher": "Carinthia Technology Institute, Villach, Austria",
                    "year": "2004",
                    "url":"/papers/ICL2004CondadoEtAl.pdf"
                },
                {
                    "authors": "P. F. Miquelina, P. A. Condado, C. L. Carvalho, H. Shahbazkia, and F. G. Lobo",
                    "title": "Toque de voz: Sistema de síntese de voz com um teclado virtual para o auxílio de pessoas com necessidades educativas especiais",
                    "titlebook": "Proceedings of RiBiE2004: 7th IberoAmerican Congress on Computers in Education",
                    "pages": "650-659",
                    "city": "Monterrey, Mexico",
                    "year": "2004",
                    "url":"/papers/RiBiE2004MiquelinaEtAl.pdf"
                },
                {
                    "authors": "P. A. Condado and F. G. Lobo",
                    "title": "Communication and information technology in pre-primary school",
                    "titlebook": "Proceedings of m-ICTE 2003: Second International Conference on Multimedia and Information and Communication Technologies in Education",
                    "volume":"3",
                    "pages": "1455-1457",
                    "city": "Badajoz, Spain",
                    "year": "2003",
                    "url":"/papers/m-ICTE2003CondadoAndLobo-b.pdf"
                },
                {
                    "authors": "P. Condado, F. Tomaz, H. Shahbazkia, and F. G. Lobo",
                    "title": "Information and communication technologies for special needed persons: A case study with a student with cerebral paralysis",
                    "titlebook": "Proceedings of m-ICTE 2003: Second International Conference on Multimedia and Information and Communication Technologies in Education",
                    "volume":"3",
                    "pages": "1470-1474",
                    "city": "Badajoz, Spain",
                    "year": "2003",
                    "url":"/papers/m-ICTE2003CondadoAndLobo-a.pdf"
                }
            ]
        },

        {

            "type": "reports",
            "title": "Technical Reports",
            "publications":[
                {
                    "authors": "F. G. Lobo, M. Zacarias, P. A. Condado, T. Romão, R. Godinho, and M. Moreno",
                    "title": "Evaluating Accessible Synchronous CMC Applications",
                    "arxiv": "1005.1200v3",
                    "year": "2011",
                    "url": "https://arxiv.org/pdf/1005.1200v3",
                    "doi": "arXiv Report No. 1005.1200v3"
                }, 
                {
                    "authors": "P. A. Condado and F. G. Lobo.",
                    "title": "EasyVoice: Integrating voice synthesis with Skype",
                    "instituition": "UAlg-ILab Report No. 200705, UAlg Informatics Laboratory, University of Algarve",
                    "city": "Faro, Portugal",
                    "arxiv": "0706.3132",
                    "year": "2007",
                    "url": "https://arxiv.org/pdf/0706.3132",
                    "doi": "arXiv Report No. 0706.3132"
                }, 
                {
                    "authors": "P. A. Condado and F. G. Lobo.",
                    "title": "Breaking barriers for people with voice disabilities: Combining virtual keyboards with speech synthesizers, and VoIP applications",
                    "instituition": "UAlg-ILab Report No. 200604, UAlg Informatics Laboratory, University of Algarve",
                    "city": "Faro, Portugal",
                    "arxiv": "cs.CY/0606088",
                    "year": "2006",
                    "url": "https://arxiv.org/pdf/cs.CY/0606088",
                    "doi": "arXiv Report No. cs.CY/0606088"
                }

        ]
        }

]
}

      );
  
    useEffect(() => {
      async function getData(){
        try{
          const request = await api.get('publications/');
          setData(request.data);
          setIsReaded(true);
          return request;
        }
        catch(e){
          return null;
        }
      } 
      getData();
    }, []);
      
   const getPublications = (publication) =>{

    const itsTheses = _ =>{
      return(
        <div className="publications pageContainer ">
        {publication.publications?.map((publication, index) =>{
            return (
              <Card key={index}>
                <Thesis publication={publication} />
              </Card>
      
      )
          } )
        }
        </div> 
      )
            
           
          
    }
    const itsArticles = _ =>{
      return(
        <div className="publications pageContainer ">
        {publication.publications?.map((publication, index) =>{
            return (
              <Card key={index}>
                <Article publication={publication} />
              </Card>
      
      )
          } )
        }
        </div> 
      )
      
    }
    const itsPapers = _ =>{
      return(
        <div className="publications pageContainer">
        {publication.publications?.map((publication, index) =>{
            return (
              <Card key={index}>
                <Paper publication={publication} />
              </Card>
              /*<p className="publication" key={index}>
                {publication.url? (<span><a href={publication.url} title="Download" className="special-btn">{publication.doi? /*publication.doi* / (<i className="fa fa-download"></i>): ((<i className="fa fa-download"></i>)/*<i>PDF</i>* /)}</a></span>):('')} {publication.youtube? (<a href={publication.youtube} title="Presentation" className="special-btn youtube"><i className="fa fa-youtube"></i></a>):('')}
                {publication.acm? (<img src="https://dl.acm.org/images/oa.gif" width="25" height="25" border="0" alt={publication.acm} style={{verticalAlign:"middle"}} />):('')}   
                {publication.authors? (<span>{publication.authors}.</span>):('')} {publication.title? (<span><b>{publication.title}</b>.</span>):('')} {publication.titlebook? (<span>In <i>{publication.titlebook}</i>, </span>):('')}  
                {publication.publication? (<span>{publication.publication},</span>):('')} {publication.volume? (<span>Vol. {publication.volume},</span>):('')} {publication.pages? (<span>pages {publication.pages},</span>):('')} {publication.publisher? (<span>{publication.publisher},</span>):('')}  
                {publication.city? (<span>{publication.city},</span>):('')} {publication.year}.
                </p>*/
            )
          } )
        }
        </div> 
      )
    }
    const itsReports = _ =>{
        
        return(
          <div className="publications pageContainer">
          {publication.publications?.map((publication, index) =>{
              return (
                <Card key={index}>
                  <Report publication={publication} />
                </Card>
        
        )
            } )
          }
          </div> 
        )  
      }

    const publications = {
      theses: itsTheses,
      articles: itsArticles,
      papers: itsPapers,
      reports: itsReports,
      default: _=>{
        return ''
      }
    } 
    return (publications[publication.type] || publications.default)()
  }

  const renderPublications =() =>{
  //  let data = data;
    return(
        <span>
        {data.publications?.map((publication, index) =>{
              if(publication.title){
                return(
                  <Card key={index}>
                    <h3>{publication.title}</h3>
                    {getPublications(publication)}
                  </Card>
                  
                  )  
                }
                return '';
              })
              }
          </span>)             
    }
  




//render(){
  if (!isReaded){
    return (<Loading />);
  }
 /* */
 
  return(
   <main> 
    <section className="page">
     
      <Card style={{'backgroundImage': 'url(/public/images/publications-img.png)'}}>
      <div className="black-v">
              <div className="black-h">
                  <div className="pageHeader">
                    <div className="pageHeaderContainer publications">
                      <div className="div-with-video">
                          <h1 className="title">
                            <span className="effect-1">My</span> publications are listed below. 
                          </h1>
                          <div className="pageContainer">
                          <p>
                            My <span className="effect-1">research interests</span> include <span className="effect-1">computer assistive technologies</span>, human-computer 
                            interaction, <span className="effect-1">user-centred design</span>, domotic systems,  <span className="effect-1">human-robot 
                            interaction</span>, machine learning and <span className="effect-1">evolutionary computation</span>.
                          </p>
                          <div className="items">
                              <Link to="/publications#papers" className="cir-btn"><i className="fa fa-angle-down"></i></Link>
                          </div>

                        </div>
                      </div>
                
                    </div>
                  </div>
                </div>
              </div> 
      </Card>

      
      <div  id="papers" className="empty-bar" style={{paddingBottom: '86px'}}></div>
      <div className="publications">
        {renderPublications()}
      </div>
      <div className="empty-bar"></div>
      
     </section>
   </main>
   )
}


//}
/*const Publications = (props) => {
  return(
    
    <section className="publications">
     
      <Card style={{'backgroundImage': 'url(/public/images/publications-img.png)'}}>
        <div className="publicationsHeader">
          <div>
            <h1 className="title"> 
              Publications
            </h1>
            <div className="publicationsContainer">
            <p>My research interests include computer assistive technologies, human-computer interaction, user-centred design, domotic systems, human-robot interaction, machine learning and evolutionary computation.
All of my scientific publications are listed below. </p>
            </div>
          </div>
        </div>
      </Card>
    
      <SpecialCard>
        <h3>Theses</h3>
        <p>P.&nbsp;A. Condado. <b>Quebra de barreiras de comunicação para portadores de paralisia cerebral</b>. 
        <em>PhD thesis</em>, Universidade do Algarve, Portugal, 2009.</p>
      </SpecialCard>
      <SpecialCard>
        <h3>Journal Articles</h3>
        <p>R.&nbsp;Godinho, P.&nbsp;A. Condado, M.&nbsp;Zacarias and F.&nbsp;G. Lobo. <b>Improving accessibility 
          of mobile devices with easywrite</b>. Behaviour & Information Technology, 34(2):135-150, 2015.
(<a href="https://dx.doi.org/10.1080/0144929X.2014.981584">DOI: 10.1080/0144929X.2014.981584</a>) </p>

        <p>D.&nbsp;Datta, K.&nbsp;Deb, C.&nbsp;M. Fonseca, F.&nbsp;G. Lobo, P.&nbsp;A. Condado, and J.&nbsp;Seixas. <b>Multi-objective 
          evolutionary algorithm for land-use management problem.</b> In <em>International Journal of Computational 
            Intelligence Research</em>, 3(4):371-384, 2007.</p>
      </SpecialCard>
      <SpecialCard>
        <h3>Conference Papers</h3>
        <p><img src="https://dl.acm.org/images/oa.gif" width="25" height="25" border="0" alt="ACM DL Author-ize service" style={{verticalAlign:"middle"}} />
        P.&nbsp;A. Condado and F.&nbsp;G. Lobo. <b><a href="https://dl.acm.org/authorize?N07956" 
        title="A System for Controlling Assisted Living Environments Using Mobile Devices">A System for Controlling Assisted Living 
        Environments Using Mobile Devices</a></b>. In <em>Proceedings of the 17th International ACM SIGACCESS Conference on Computers 
          and Accessibility</em> (ASSETS 2015), pages 33-38. ACM Press, 2015. (<a href="https://youtu.be/JjJYrsSYbnM" title="Presentation"> <i className="fa fa-youtube"></i></a>)</p>

        <p>P.&nbsp;A. Condado, R.&nbsp;Godinho, M.&nbsp;Zacarias and F.&nbsp;G. Lobo. <b>EasyWrite: A touch-based entry method 
          for mobile devices</b>. In <em>Proceedings of the 13th IFIP TC13 International Conference on Human-Computer Interaction 
            (INTERACT 2011), Workshop on Mobile Accessibility (MOBACC 2011)</em>, Lisboa, Portugal, 2011.</p>

        <p>P.&nbsp;A. Condado and F.&nbsp;G. Lobo. <b>EasyVoice: Breaking barriers for people with voice disabilities</b>. 
        In <em>Proceedings of the 11th International Conference on Computers Helping People with Special Needs (ICCHP 2008)</em>, Lecture 
        Notes in Computer Science, Vol. 5105, pages 1228-1235. Springer, 2008. The definitive 
        version is available at <a href="https://dx.doi.org/10.1007/978-3-540-70540-6_185">http://dx.doi.org/10.1007/978-3-540-70540-6_185</a></p>

        <p> <img src="https://dl.acm.org/images/oa.gif" width="25" height="25" border="0" alt="ACM DL Author-ize service" style={{verticalAlign:"middle"}} /> 
        P.&nbsp;A. Condado and F.&nbsp;G. Lobo. <b><a href="https://dl.acm.org/authorize?N07955" 
        title="EasyVoice: integrating voice synthesis with Skype">EasyVoice: integrating voice synthesis with Skype</a></b>. In <em>Proceedings 
          of the Ninth International ACM SIGACCESS Conference on Computers and Accessibility</em> (ASSETS 2007), pages 235-236. ACM Press, 2007.</p>

        <p>S.&nbsp;Norte, P.&nbsp;A. Condado, and F.&nbsp;G. Lobo. <b>Learning Logo at a high school: Constructionism versus Objectivism.</b> 
          In <em> Proceedings of the 11th Eurologo Conference</em>, Bratislava, Slovakia, 2007.</p>

        <p>S.&nbsp;Norte, N.&nbsp;Castilho, P.&nbsp;A. Condado, and F.&nbsp;G. Lobo. <b>GoGoBoard and Logo programming for helping people with 
          disabilities.</b> In <em>Proceedings of the 10th Eurologo Conference</em>, pages 171-178, Warsaw, Poland, 2005. (<a href="papers/E2005NorteEtAl.pdf">E2005NorteEtAl.pdf</a>)</p>

        <p>P.&nbsp;A. Condado, P.&nbsp;F. Miquelina, S.&nbsp;Norte, N.&nbsp;Castilho, F.&nbsp;G. Lobo, and H.&nbsp;Shahbazkia. <b>Information and 
          communication technologies for people with disabilities.</b> In <em>CDROM Proceedings of ICL 2004: Interactive Computer Aided Learning 
            International Conference</em>, Carinthia Technology Institute, Villach, Austria, 2004. (<a href="papers/ICL2004CondadoEtAl.pdf">ICL2004CondadoEtAl.pdf</a>)</p>
      
         <p>P.&nbsp;F. Miquelina, P.&nbsp;A. Condado, C.&nbsp;L. Carvalho, H.&nbsp;Shahbazkia, and F.&nbsp;G. Lobo. <b>Toque de voz: Sistema de 
              síntese de voz com um teclado virtual para o auxílio de pessoas com necessidades educativas especiais.</b> In <em>Proceedings of RiBiE2004: 7th 
                IberoAmerican Congress on Computers in Education</em>, pages 650-659, Monterrey, Mexico, 2004. (<a href="papers/RiBiE2004MiquelinaEtAl.pdf" >RiBiE2004MiquelinaEtAl.pdf</a>)</p>

          <p>P.&nbsp;Condado and F.&nbsp;G. Lobo. <b>Communication and information technology in pre-primary school.</b> In <em>Proceedings of m-ICTE 
            2003: Second International Conference on Multimedia and Information and Communication Technologies in Education</em>, volume 3, pages 1455-1457, Badajoz, Spain, 2003. 
            (<a href="papers/m-ICTE2003CondadoAndLobo-b.pdf">m-ICTE2003CondadoAndLobo-b.pdf</a>)</p>

          <p>P.&nbsp;Condado, F.&nbsp;Tomaz, H.&nbsp;Shahbazkia, and F.&nbsp;G. Lobo. <b>Information and communication technologies for special needed 
            persons: A case study with a student with cerebral paralysis.</b> In <em>Proceedings of m-ICTE 2003: Second International Conference on Multimedia and Information 
              and Communication Technologies in Education</em>, volume 3, pages 1470-1474, Badajoz, Spain, 2003. (<a href="papers/m-ICTE2003CondadoAndLobo-a.pdf">m-ICTE2003CondadoAndLobo-a.pdf</a>)</p>
      </SpecialCard>
      <SpecialCard>
        <h3>Technical Reports</h3>
        <p>F.&nbsp;G. Lobo, M. Zacarias, P.&nbsp;A. Condado, T. Romão, R. Godinho, and M. Moreno. <b>Evaluating Accessible Synchronous CMC Applications.</b> arXiv Report No. 1005.1200v3, 
        2011. (<a href="https://arxiv.org/pdf/1005.1200v3">http://arxiv.org/pdf/1005.1200v3</a>)</p>
        
        <p>P.&nbsp;A. Condado and F.&nbsp;G. Lobo. <b>EasyVoice: Integrating voice synthesis with Skype.</b> UAlg-ILab Report No. 200705, UAlg Informatics Laboratory, University of Algarve, Faro, 
        Portugal, 2007. Also as arXiv Report No. 0706.3132. (<a href="https://arxiv.org/pdf/0706.3132">http://arxiv.org/pdf/0706.3132</a>)</p>
        
        <p>P.&nbsp;A. Condado and F.&nbsp;G. Lobo. <b>Breaking barriers for people with voice disabilities: Combining virtual keyboards with speech synthesizers, and VoIP applications.</b> UAlg-ILab 
        Report No. 200604, UAlg Informatics Laboratory, University of Algarve, Faro, Portugal, 2006. Also as arXiv Report No. cs.CY/0606088. 
        (<a href="https://arxiv.org/pdf/cs.CY/0606088">http://arxiv.org/pdf/cs.CY/0606088</a>)</p>

      </SpecialCard>
     </section>
   )

 }*/

export default Publications;
import React, {useState, useEffect} from 'react';
import {NavLink} from 'react-router-dom';
import './style.css';
//import { useFetch } from '../../services/useFetch';
import api from '../../services/api';


/**
* @author
* @function Footer
**/

const Footer = () =>{

  /*const [isReaded, setIsReaded] = useState(true);*/
  const [dataFooter, setDataFooter] = useState(
   
    { "footer":
    {
        "photo": "/public/images/PauloCondado.png",
        "email": "p.condado@fct.unl.pt",
        "center": {
            "name": "Center for Environmental and Sustainability Research",
            "url":"https://cense.fct.unl.pt/"
        },
        "faculty": {
            "name": "FCT",
            "url":"https://www.fct.unl.pt/"
        },
        "instituition": {
            "name": "NOVA University Lisbon",
            "url":"https://www.unl.pt/"
        },
        "campus": "Campus de Caparica",
        "address": "2829-516 Caparica, Portugal",
        "update": "02/01/2024"
        
    }
}

);

  useEffect(() => {
    async function getData(){
      try{
        const request = await api.get('footer/');
        setDataFooter(request.data);
        /*setIsReaded(true);*/
        return request;
      }
      catch(e){
        return null;
      }
    } 
    getData();
  }, []);
    
  


//render(){
        let data = dataFooter.footer;
  /*      if (!this.state.isReaded){
            return (
                <div className="footer">
                
                        <footer className="second-footer">
                            <section className="left"  id="footer-menu">
                                <ul className="footer-menu">
                                    <li id="home"><NavLink to="/" exact activeClassName="active">Home</NavLink></li>
                                    <li id="publications"><NavLink to="/publications" activeClassName="active">Publications</NavLink></li>
                                    <li id="press"><NavLink to="/press" activeClassName="active">Press</NavLink></li>
                                    <li id="activities"><NavLink to="/activities" activeClassName="active">Activities</NavLink></li>
                                    <li id="links"><NavLink to="/links" activeClassName="active">Other links</NavLink></li>
                                </ul>
                            </section>
                            
                            <section className="right">
                                <p>&copy; Paulo Condado 2016-2020</p>
                            </section>
                        </footer>
                </div>
            );
          }
        */ 

        return(
            <div className="footer">
             <footer className="footer">
                 <section className="one-three-cell" id="footer-contact">
                         <h3>Contact</h3>
                         <div className="footerContainer">    
                         {data.photo? (<img src={data.photo} alt="Paulo Condado" />):('')}
                             <p><b><i>Paulo Condado</i></b><br/>
                                    {data.email? (data.email):('')}
                                    <br />
                                    {data.center.name? (data.center.url? (<a href={data.center.url} title={data.center.name}>{data.center.name}</a>):(<b>{data.center.name}</b>)):('')}
                                    <br />
                                    {data.faculty.name? (data.faculty.url? (<a href={data.faculty.url} title={data.faculty.name}>{data.faculty.name}</a>):(<b>{data.faculty.name}</b>)):('')}
                                    {data.faculty.name? (data.instituition.name? (<span> - </span>):('')):('')}
                                    {data.instituition.name? (data.instituition.url? (<a href={data.instituition.url} title={data.instituition.name}>{data.instituition.name}</a>):(<b>{data.instituition.name}</b>)):('')}
                                    <br />
                                    {data.campus? (data.campus):('')}
                                     <br />
                                     {data.address? (data.address):('')}
                             </p>
                         </div> 
                 </section>
                     <section className="one-three-cell" id="footer-social">
                         <h3>Social Networks</h3>
                                 
                         <ul className="social">
                                         <li><a href="https://www.facebook.com/PauloCondadoOficial/" title="Facebook"><i className="fa fa-facebook"></i></a></li>
                                         <li><a href="https://pt.linkedin.com/in/pauloacondado" title="LinkedIn"><i className="fa fa-linkedin"></i></a></li>              
                                         <li><a href="https://www.youtube.com/channel/UCbErgh3EqMxcWrK3bt2Qwpg" title="Youtube"><i className="fa fa-youtube"></i></a></li>              
                         </ul>
                 </section>
                     <section className="one-three-cell" id="footer-update">
                         <h3>Last update</h3>
                         <p>{data.update? (data.update):('')}</p>
                 </section>
                 </footer>
         
                 <footer className="second-footer">
                     <section className="left"  id="footer-menu">
                         <ul className="footer-menu">
                             <li id="home"><NavLink to="/" exact activeClassName="active">Home</NavLink></li>
                             <li id="publications"><NavLink to="/publications" activeClassName="active">Publications</NavLink></li>
                             <li id="press"><NavLink to="/press" activeClassName="active">Press</NavLink></li>
                             <li id="activities"><NavLink to="/activities" activeClassName="active">Activities</NavLink></li>
                            {// <li id="links"><NavLink to="/links" activeClassName="active">Other links</NavLink></li>
                            }
                        </ul>
                     </section>
                     
                     <section className="right">
                         <p>&copy; <span className="effect-1">Paulo</span> Condado <span className="effect-1">2016</span>-2024</p>
                     </section>
                 </footer>
         </div>
            )
             
    }
//}
/* 
const Footer = () => {
  return(
   <div className="footer">
    <footer className="footer">
        <section className="one-three-cell" id="footer-contact">
                <h3>Contact</h3>
                <div className="footerContainer">    
                    <img src="/public/images/PauloCondado.png" alt="Paulo's photo" />
                    <p><b><i>Paulo Condado</i></b><br/>
                        p.condado@fct.unl.pt<br />
                        <a href="https://cense.fct.unl.pt/">Center for Environmental and Sustainability Research</a> <br />
                        <a href="https://www.fct.unl.pt/">FCT</a> - <a href="https://www.unl.pt/">NOVA University Lisbon</a> <br />
                        Campus de Caparica <br />
                        2829-516 Caparica, Portugal
                    </p>
                </div> 
        </section>
            <section className="one-three-cell" id="footer-social">
                <h3>Social Networks</h3>
                        
                <ul className="social">
                                <li><a href="https://www.facebook.com/PauloCondadoOficial/" title="Facebook"><i className="fa fa-facebook"></i></a></li>
                                <li><a href="https://pt.linkedin.com/in/pauloacondado" title="LinkedIn"><i className="fa fa-linkedin"></i></a></li>              
                                <li><a href="https://www.youtube.com/channel/UCbErgh3EqMxcWrK3bt2Qwpg" title="Youtube"><i className="fa fa-youtube"></i></a></li>              
                </ul>
        </section>
            <section className="one-three-cell" id="footer-update">
                <h3>Last update</h3>
                <p>12/10/2018</p>
        </section>
        </footer>

        <footer className="second-footer">
            <section className="left"  id="footer-menu">
                <ul className="footer-menu">
                    <li id="home"><NavLink to="/" exact activeClassName="active">Home</NavLink></li>
                    <li id="publications"><NavLink to="/publications" activeClassName="active">Publications</NavLink></li>
                    <li id="press"><NavLink to="/press" activeClassName="active">Press</NavLink></li>
                    <li id="activities"><NavLink to="/activities" activeClassName="active">Activities</NavLink></li>
                    {//<li id="links"><NavLink to="/links" activeClassName="active">Other links</NavLink></li>
                    }
                </ul>
            </section>
            
            <section className="right">
                <p>&copy; <span className="effect-1"> Paulo </span> Condado  <span className="effect-1">2016</span>-2021</p>
            </section>
        </footer>
</div>
   )

 }
/**/
export default Footer;
import React, {Component} from 'react';
import './App.css';
import Home from './containers/Home';
import Header from './components/Header';
import Footer from './components/Footer';
import {BrowserRouter as Router,Switch,Route} from 'react-router-dom';
import Publications from './containers/Publications';
import Press from './containers/Press';
import Activities from './containers/Activities';
//import OtherLinks from './containers/OtherLinks';
import NotFound from './containers/NotFound';
import ScrollUp from './components/ScrollUp';


class App extends Component{

  constructor(){
    super();
    this.state ={
      scrolled: false,
    };
  }


  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, true);
   // window.addEventListener('resize', this.handleScroll, true);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    //window.removeEventListener('resize', this.handleScroll);
  }
  nav = React.createRef();
    
  handleScroll = () => {
    let isTop = window.scrollY < 100;
    
    if (isTop) {
      this.setState({scrolled: false});
    }
    else {
      this.setState({scrolled: true});
    }
    
  }

  render(){
    return (
      <Router>
        <div className="App">
        <ScrollUp>
          <Header scrolled={this.state.scrolled}/> 
          <Switch>  
          <Route exact path="/" component={Home} />
          <Route path="/publications/" component={Publications} />
          <Route path="/press/" component={Press} />
          <Route path="/activities/" component={Activities} />
         {// <Route path="/links" component={OtherLinks} />
         }<Route component={NotFound} />
          </Switch>
          <Footer />
        </ScrollUp>
        </div>
      </Router>
    );  
  }
}
/*function App() {

  return (
    <Router>
      <div className="App">
      <Header />
      <Route exact path="/" component={Home} />
      <Route path="/publications" component={Publications} />
      <Route path="/press" component={Press} />
      <Route path="/activities" component={Activities} />
      <Route path="/links" component={OtherLinks} />
      <Footer />
      </div>
    </Router>
  );
}*/

export default App;

import React, {useCallback, useEffect, useState} from 'react';
import './style.css';
import Card from '../../components/UI/Card';
//import { useFetch } from '../../services/useFetch';
//
import api from '../../services/api';

import Video from '../../components/UI/Video';
import Award from './Award';
import Event from './Event';
import Loading from '../Loading';
import { HashLink as Link } from 'react-router-hash-link';
import SpecialCard from '../../components/UI/SpecialCard';

//import { Link } from 'react-router-dom';


/**
* @author
* @function Home
**/
/*const state = {
  isReaded: true,
  data: [] 
}*/



//class Home extends Component{
const Home = ()=> {
 

  const [isReaded, setIsReaded] = useState(true);
  const [data, setData] = useState(
    {
      "noevent":{
          "title":"",
          "description":"",
          "url":"",
          "date":"07/18/2020",
          "expire":"07/29/2020 23:00:00",
          "nomedia":{
              "image": "",
              "youtube": ""
          }
      },
      "awards":{
          "title":"Awards and Honors",
          "list":[
              {
              "received":"I received an Honorable Mention in the 2016 Alumni Career Award from the University of Algarve",
              "url":"https://www.paulocondado.net/public/images/ualg_premiocarreira.gif",
              "youtube":"https://youtu.be/at-uokZN0vk?t=16m55s"
  
              }
          ]
      },
      "interests":{
          "title": "My Interests",
          "text": "I am interested in Computer Assistive Technologies, Human-Computer Interaction, User-Centred Design, Domotic Systems, Human-Robot Interaction, Evolutionary Computation and Machine Learning"
      },
      "videos":[
          {
          
              "duration": "2:02",
              "title": "EasyVoice: Breaking barriers for people with voice disabilities",
              "url": "https://www.youtube-nocookie.com/embed/I4URUp04twE"  
          },
          {
                  
              "duration": "1:36",
              "title": "A text-entry method for mobile devices for people with hand coordination problems",
              "url": "https://www.youtube-nocookie.com/embed/XIbs2b7-qa4"  
          },
          {
                  
              "duration": "1:20",
              "title": "A system developed to allow people with disabilities to control their home environment using a smartphone",
              "url": "https://www.youtube-nocookie.com/embed/mHThEKUsRXM"  
          },
          {
                  
              "duration": "9:40",
              "title": "Paulo Condado at TEDxEdges - October 1st, 2010, in Portimão, Algarve",
              "url": "https://www.youtube-nocookie.com/embed/GFJLx3yOlIc"  
          },
          {
                  
              "duration": "2.25",
              "title": "Paulo Condado and Fernando Lobo at TEDxEdge - October 1st, 2010",
              "url": "https://www.youtube-nocookie.com/embed/6pFnQdOXU9Y"  
          }
      ]
  }
  
    );
  /*componentDidMount(){
    useFetch('home.json').then(data => this.setState(
      {
        isReaded: true,
        data: data
      }
    )).catch(err => {
      console.log(err);
      return null;
  });  
  }*/
  
/* data = async () => {
    const data = await axios
    .get('/public/data/home/json')
    .then(res => this.setState({
      isReaded: true,
      data: res.data
    }));
  };
  
*/
  useEffect(() => {
    async function getData(){
      try{
        
        const request = await api.get('home/');
        setData(request.data);
        setIsReaded(true);
        return request;
      }
      catch(e){
        return null;
      }
    }
     
    getData();
    
  }, []);

  const [scrollX, setScrollX] = useState(0);
  const [videosWidth, setVideosWith] = useState(data.videos.length*550);


  const updateVideosWith = useCallback(() =>{
    let videosW = data.videos.length * 390;
    if(window.innerWidth > 768) {
      videosW = data.videos.length * 550;
    }
    setVideosWith(videosW);
  }, [data])

  useEffect(() => {
    const handleResize =  () =>{
      updateVideosWith();
      setScrollX(0);
    }
    window.addEventListener("resize", handleResize, false);
  },[updateVideosWith]);

  const handleLeftRow = () => {
    let x = scrollX + Math.round(window.innerWidth / 2);
    updateVideosWith();
    if(x > 0){
      x = 0;
    }
    setScrollX(x);
  }
  
 
  const handleRightRow = () => {
    let x = scrollX - Math.round(window.innerWidth / 2);
    updateVideosWith();
    if((window.innerWidth  - videosWidth) > x){
      x = (window.innerWidth - videosWidth) - 60

    }
    
    setScrollX(x);
  }
  
  


  const renderVideos =() =>{
    let videos = data.videos;
    if(videos){
      return (
      <Card>  
        <div className="pageVideoContainer"> 
          
          {((scrollX !== 0) && (videosWidth > window.innerWidth))?(<div className="listLeft" onClick={handleLeftRow}><i className="fa fa-angle-left"></i></div>):('') }
          {((scrollX  > (window.innerWidth - videosWidth) - 60) && (videosWidth > window.innerWidth))?(<div className="listRight"onClick={handleRightRow}><i className="fa fa-angle-right"></i> </div>):('')} 
          <div className="listVideos" style= {
            {marginLeft: scrollX}
          }>

            {videos.map((video,index) =>{
              return(
                <Video video={video} key={index} />
              )   
              })} 
          </div>
        </div>
    
     </Card>
      )
    }
  }

  const renderInterests =() =>{
    let interests = data.interests;
    if(interests){
      return (
        <Card>
          {interests.title? (<h3>{interests.title}</h3>):('')}
          <SpecialCard>
            {interests.text? (<p>{interests.text}.</p>):('')}
          </SpecialCard>
      </Card>
      )
    }
  }
  
  const renderAwards =() =>{
    let awards = data.awards;
    if(awards && awards.list){
      return (
        <Card>
        <h3>{awards.title}</h3>
        {awards.list.map((award, index) =>{
         return(
          <SpecialCard  key={index}>
            <Award award={award} />
          </SpecialCard>
        )   
     })}
        
      </Card>
      )
    }
  }

 const renderInterestsAwards =() =>{
   // let data = data;
    if(data.awards || data.interests){
      return (
        <Card>
        <div className="pageContainer group">
          {renderAwards()}
          {renderInterests()}
        </div>
      </Card> 
      )
    }
  }
    

 const renderEvent =() =>{
    let event = data.event;
    let date = new Date().getTime();
    if(event){
       if(/*(new Date(event.date).getTime()) <= (new Date(date).getTime()) &&*/ (new Date(date).getTime()) <= (new Date(event.expire).getTime())){
          return (
            <Event event={event} />
          )
        }
    }
  }

 // render(){
  
    if (!isReaded){
      return (<Loading />);
    }
    
    return(
      <main>
        <section className="page">
        
          <Card style={{backgroundImage: 'url(/public/images/about-img.png)'}}>
            <div className="black-v">
              <div className="black-h">
                  <div className="pageHeader">
                    <div className="pageHeaderContainer">
                      <div className="div-with-video">
                          <h1 className="title">
                            <span className="effect-1">My</span> name is Paulo Condado.  
                          </h1>
                          <div className="pageContainer">
                          <p>
                            I have a <span className="effect-1">PhD</span> in Electronic Engineering and Computer 
                            Science (<span className="effect-1">2009</span>) and a <span className="effect-1">4-year 
                            undergraduate degree</span> in Computer Science and Business (<span className="effect-1">2002</span>), 
                            both from the University of Algarve. <br /><br />I am an <span className="effect-1">Assistant Researcher</span> (FCT/MCTES 
                            – <a href="https://doi.org/10.54499/CEECIND/00578/2017/CP1462/CT0002" alt="DOI: CEECIND/00578/2017/CP1462/CT0002">CEECIND/00578/2017/CP1462/CT0002</a>) at <a href="https://cense.fct.unl.pt/" alt="CENSE"><span className="effect-1">CENSE</span> – Center for Environmental 
                            and Sustainability Research</a> from  <a href="https://www.fct.unl.pt/" alt="FCT NOVA">FCT NOVA</a>.
                          </p>
                          <div className="items">
                              <Link to="/#interests" className="cir-btn"><i className="fa fa-angle-down"></i></Link>
                          </div>

                        </div>
                      </div>
                
                    </div>
                  </div>
                </div>
              </div> 
          
          </Card>

          <div  id="interests" className="empty-bar" style={{paddingBottom: '86px'}}></div>
          {renderInterestsAwards()
          }  

          <div className="empty-bar"></div>
      
          {renderEvent()}  
      
          <div className="empty-bar"></div>
          
          {renderVideos()}  
                      
          <div className="empty-bar"></div>
      
          
          
        </section>
      </main>
     )       
  }  
//}
/*const Home = (props) => {

  
  useFetch('home.json').then(data => console.log(data));
  //console.log(data);


  return(
    
    <section className="home">
     
      <Card style={{backgroundImage: 'url(/public/images/about-img.png)'}}>
        <div className="homeHeader">
          <div>
            <h1 className="title">
              About  
            </h1>
            <div className="homeContainer">
              <p>I have a PhD in Electronic Engineering and Computer Science (2009)
and a 4-year undergraduate degree in Computer Science and Business (2002),
both from the University of Algarve. 
              </p>
              <p>I am an Assistant Researcher (FCT/MCTES – CEECIND/00578/2017) at <a href="https://cense.fct.unl.pt/">CENSE – Center for
Environmental and Sustainability Research</a> from  <a href="https://www.fct.unl.pt/">FCT NOVA</a>.
              </p>
            </div>
          </div>
        </div>
      </Card>
      <SpecialCard>
        <div className="homeContainer">
          <Card>
            <h3>Awards and Honors</h3>
            <p>I received an <a href="https://www.paulocondado.net/public/images/ualg_premiocarreira.gif">Honorable Mention</a> in the 2016 Alumni Career Award from the University of Algarve. <a href="https://youtu.be/at-uokZN0vk?t=16m55s"><i className="fa fa-youtube"></i></a></p>
          </Card>
          <Card>
            <h3>My Interests</h3>
            <p>I am interested in Computer Assistive Technologies, Human-Computer Interaction,
User-Centred Design, Domotic Systems, Human-Robot Interaction, Evolutionary Computation
and Machine Learning. </p>
          </Card>
        </div>
      </SpecialCard> 
      <Card>


        <div className="homeVideoContainer">
            <SpecialCard video= "true">
              <div className="media">
              <iframe src="https://www.youtube-nocookie.com/embed/I4URUp04twE" title="EasyVoice: Breaking barriers for people with voice disabilities." frameBorder="0" allowFullScreen></iframe>    
              </div>
              <p>EasyVoice: Breaking barriers for people with voice disabilities. (2:02)</p>
            </SpecialCard>
            <SpecialCard video= "true">
              <div className="media">
              <iframe src="https://www.youtube-nocookie.com/embed/XIbs2b7-qa4" title="A text-entry method for mobile devices for people with hand coordination problems."  frameBorder="0" allowFullScreen>EasyWrite</iframe>
              </div>
              <p>A text-entry method for mobile devices for people with hand coordination problems. (1:36)</p>
            </SpecialCard>
            <SpecialCard video= "true">
              <div className="media">
              <iframe src="https://www.youtube-nocookie.com/embed/mHThEKUsRXM" title="A system developed to allow people with disabilities to control their home environment using a smartphone."  frameBorder="0"  allowFullScreen>EasyHouse</iframe>
              </div>
              <p>A system developed to allow people with disabilities to control their home environment using a smartphone. (1:20)</p>
            </SpecialCard>
            <SpecialCard video= "true">
              <div className="media">
              <iframe src="https://www.youtube-nocookie.com/embed/GFJLx3yOlIc" title="TEDxEdges - Paulo Condado"  frameBorder="0" allowFullScreen>TEDxEdges - Paulo Condado</iframe>
              </div>
              <p>Paulo Condado at TEDxEdges - October 1st, 2010, in Portimão, Algarve, presents his project "EasyVoice" and does a surprising revelation at the end of his testimony. (9:40)</p>
            </SpecialCard>
            <SpecialCard video= "true">
              <div className="media">
              <iframe src="https://www.youtube-nocookie.com/embed/6pFnQdOXU9Y" title="Entrevista ao Paulo Condado e Fernando Lobo, no final do TEDxEdges 01-10-2010."  frameBorder="0" allowFullScreen>Entrevista ao Paulo Condado e Fernando Lobo, no final do TEDxEdges 01-10-2010. (2:25)</iframe>
              </div>
              <p>Paulo Condado and Fernando Lobo at TEDxEdge - October 1st, 2010. (2:25)</p>
            </SpecialCard>
          </div> 
      </Card>
      <div className="empty-bar"></div>
   
      
       
     </section>
   )

 }*/

export default Home;
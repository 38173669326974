import React from 'react';
import Card from '../../../components/UI/Card';
import SpecialCard from '../../../components/UI/SpecialCard';

/**
* @author
* @function Event
**/

const Event = (props) => {
    const {event} = props
    let eventAlert = {
      "title": event.title,
      "url" : event.media?.youtube || event.media?.image
    }
    
    return(
      <Card>
      <div className="pageContainer">
      {eventAlert.url? (             
           <SpecialCard>
           <div className="media">
           {event.media.youtube? (
              <iframe  src={eventAlert.url} title={eventAlert.title} frameBorder="0" allowFullScreen>{eventAlert.title}</iframe>
           ): (<img src={eventAlert.url} alt={eventAlert.title} /> )}
           </div>
           <p className="pmedia">{eventAlert.title}.</p>
        </SpecialCard>

        ) : ('')}

        <Card>

          <div className="items">
           {event.title? (<h3>{event.title}</h3>):('')}
           <p>{event.description? (<span>{event.description}. </span>):('')}</p>
          {event.url? (<span><a href={event.url} className="special-btn">See more</a></span>) :''}
          </div>
        </Card>
        
      </div>
    </Card> 
   
      
   )

 }

export default Event;
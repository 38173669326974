import React from 'react';
import {NavLink, Link} from 'react-router-dom';
import './style.css';

/**
* @author
* @function Header
**/

const Header = (props) => {
  
    const {scrolled} = props
    /*  <!-- use NavLink later -->*/ 
  return(
    <div>
        <header className={scrolled ? "main-header scrolled" : "main-header"}>
          <Link to="/"><h1 className="logo">Paulo Condado</h1></Link>
          <button className="btn-menu"><i className="fa fa-bars fa-lg"></i></button>
          <nav className="menu">
          <Link to="#" className="btn-close"><i className="fa fa-close fa-lg"></i></Link>
            <ul> 
              <li id="home"><NavLink to="/" exact activeClassName="active">Home</NavLink></li>
              <li id="publications"><NavLink to="/publications/" activeClassName="active">Publications</NavLink></li>
              <li id="press"><NavLink to="/press/" activeClassName="active">Press</NavLink></li>
              <li id="activities"><NavLink to="/activities/" activeClassName="active">Activities</NavLink></li>
             {// <li id="links"><NavLink to="/links/" activeClassName="active">Other links</NavLink></li>
              }

            </ul>
          </nav>
          
      </header>
      
    </div>
   )

 }

export default Header;
import React, {useState, useEffect} from 'react';
import './style.css';
import Card from '../../components/UI/Card';
import SpecialCard from '../../components/UI/SpecialCard';
//import { useFetch } from '../../services/useFetch';
import Loading from '../Loading';
//
import api from '../../services/api';
import { HashLink as Link } from 'react-router-hash-link';

/**
* @author
* @function Activities
**/

   const Activities = () => {

  
    const [isReaded, setIsReaded] = useState(true);
    const [data, setData] = useState({ "activities":[
      {
        "type": "research",
        "title": "Jan 2010 - Dec 2015",
        "activities":[
            {
                "description": "Postdoctoral researcher at the Center for Environmental and Sustainability Research (CENSE), sponsored by the Portuguese Foundation for Science and Technology (FCT/MCTES) under grant SFRH/BPD/65187/2009, on the topic of Computers and Accessibility",
                "supervision":{
                    "name": "Fernando Lobo",
                    "url": "https://www.fernandolobo.info/"
                }
            }
    ]
    },
    {
        "type": "research",
        "title": "Jun 2004 - Apr 2009",
        "activities":[
            {
                "description": "Doctoral studies on the topic of Information and communication technologies to help people with cerebral palsy at the University of Algarve",
                "supervision":{
                    "name": "Fernando Lobo",
                    "url": "https://www.fernandolobo.info/"
                },
                "info":"The project was funded by Fundação Calouste Gulbenkian. Gulbenkian Grant no. 65538, from Sep/2004 to Aug/2007"
            }
    ]
    },
    {
        "type": "research",
        "title": "Feb 2003 - Sep 2004",
        "activities":[
            {
                "description": "Research assistant on the project 'GeneticLand: Discovering future landscapes under Climate Change Scenarios using Genetic Algorithms' (ref. POCTI/MGS/37970/2001) at the University of Algarve",
                "supervision":{
                    "name": "Fernando Lobo",
                    "url": "https://www.fernandolobo.info/"
                }
            }
    ]
    }
      ]
  });
  
  useEffect(() => {
    async function getData(){
      try{
        const request = await api.get('activities/');
        setData(request.data);
        setIsReaded(true);
        return request;
      }
      catch(e){
        return null;
      }
    } 
    getData();
  }, []);
 

  const renderActivities =() =>{
    /*let data = this.state.data;*/
    return(
        <span>
        {data.activities?.map((activities, index) =>{
              if(activities.title){
                return(
                  <Card key={index}>
                    <h3>{activities.title}</h3>
                    {activities.activities?.map((item, index) =>{
                      return(
                           <SpecialCard key={index}>
                           <p>
                            {item.supervision.name?(<span>{item.description?(<span>{item.description}, under the supervision of Professor {item.supervision.url? 
                            (<span><a href={item.supervision.url}>{item.supervision.name}</a></span>): (<span>{item.supervision.name}</span>)}.</span>):('')} </span>):
                            (<span>{item.description}.</span>)} {item.info? (<span>{item.info}.</span>):('')}  
                          </p>
                          </SpecialCard>)
                    })}
                  </Card>
                  
                  )  }
              return 0; 
            })
              }
          </span>)             
    }


  //render(){
    if (!isReaded){
      return (<Loading />);
    }
   
    return(
      <main>  
        <section className="page">
        
          <Card style={{backgroundImage: 'url(/public/images/publications-img.png)'}}>
          <div className="black-v">
              <div className="black-h">
                  <div className="pageHeader">
                    <div className="pageHeaderContainer publications">
                      <div className="div-with-video">
                          <h1 className="title">
                           <span className="effect-1">My</span> activities are listed here. 
                          </h1>
                          <div className="pageContainer">
                          <p>
                            I am an <span className="effect-1">Assistant Researcher</span> (FCT/MCTES 
                            – <a href="https://doi.org/10.54499/CEECIND/00578/2017/CP1462/CT0002" alt="DOI: CEECIND/00578/2017/CP1462/CT0002">CEECIND/00578/2017/CP1462/CT0002</a>) at <a href="https://cense.fct.unl.pt/" alt="CENSE"><span className="effect-1">CENSE</span> – Center for Environmental 
                            and Sustainability Research</a> from  <a href="https://www.fct.unl.pt/" alt="FCT NOVA">FCT NOVA</a>.
                            {//<span className="effect-1">Some</span> of my past <span className="effect-1">activities</span> are listed here in <span className="effect-1">reverse chronological</span> order.
                            }
                          </p>
                          <div className="items">
                              <Link to="/activities#past" className="cir-btn"><i className="fa fa-angle-down"></i></Link>
                          </div>

                        </div>
                      </div>
                
                    </div>
                  </div>
                </div>
              </div> 
            
          </Card>
          

          <div  id="past" className="empty-bar" style={{paddingBottom: '86px'}}></div>
          {renderActivities()}
          <div className="empty-bar"></div>        
      
        </section>
      </main>
     )
  }
//}

/*
const Activities = (props) => {
  return(
    
    <section className="activities">
     
      <Card style={{backgroundImage: 'url(/public/images/publications-img.png)'}}>
        <div className="activitiesHeader">
          <div>
            <h1 className="title"> 
              Past Activities
            </h1>
            <div className="activitiesContainer">
              <p>Some of my past activities are listed here in reverse chronological order.</p>
            </div>
          </div>
        </div>
      </Card>
    
      <SpecialCard>
        <h3>&nbsp;Jan 2010 - Dec 2015</h3>
        <p>Postdoctoral researcher at the Center for Environmental and Sustainability Research (CENSE), sponsored by 
          the Portuguese Foundation for Science and Technology (FCT/MCTES) under grant SFRH/BPD/65187/2009, on the topic 
          of Computers and Accessibility under the supervision of Professor <a href="https://www.fernandolobo.info/">Fernando Lobo</a>.
        </p>
      </SpecialCard>
      <SpecialCard>
        <h3>&nbsp;Jun 2004 - Apr 2009</h3>
        <p>Doctoral studies on the topic of Information and communication technologies to help people with cerebral palsy 
          at the University of Algarve, under the supervision of Professor <a href="https://www.fernandolobo.info/">Fernando 
          Lobo</a>. The project was funded by Fundação Calouste Gulbenkian. Gulbenkian Grant no. 65538, from Sep/2004 to Aug/2007.
        </p>
      </SpecialCard>
      <SpecialCard>
        <h3>&nbsp;Feb 2003 - Sep 2004</h3>
        <p>Research assistant on the project <b>GeneticLand: Discovering future landscapes under Climate Change Scenarios using 
          Genetic Algorithms</b> (ref. POCTI/MGS/37970/2001) at the University of Algarve, under the supervision of Professor <a href="https://www.fernandolobo.info/">Fernando Lobo</a>.
        </p>
      </SpecialCard>
     </section>
   )

 }
 */

export default Activities;